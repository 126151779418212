const browser = {
  isAndroid: function () {
    return navigator.userAgent.match(/Android/i) ? true : false;
  },
  isMobileQQ: function () {
    var ua = navigator.userAgent;
    return /(iPad|iPhone|iPod).*? (IPad)?QQ\/([\d.]+)/.test(ua) || /\bV1_AND_SQI?_([\d.]+)(.*? QQ\/([\d.]+))?/.test(ua);
  },
  isIOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
  },
  isWx: function () {
    return navigator.userAgent.match(/micromessenger/i) ? true : false;
  },
  isChrome: function () {
    return (navigator.userAgent.match(/Chrome\/([\d.]+)/) || navigator.userAgent.match(/CriOS\/([\d.]+)/)) ? true : false;
  },
  isBaidu: function () {
    return navigator.userAgent.match(/baidubrowser/i) ? true : false;
  },
  isUC: function () {
    return navigator.userAgent.match(/UCBrowser/i) ? true : false;
  },
  isSafari: function () {
    return navigator.userAgent.match(/safari/i) ? true : false;
  },
  isQQBrowser: function () {
    return navigator.userAgent.match(/MQQBrowser/i) ? true : false;
  },
  isWeibo: function () {
    return navigator.userAgent.match(/weibo/i) ? true : false;
  },
  isAlipay: function () {
    return navigator.userAgent.match(/Alipay/i) ? true : false;
  },
  isMobile: function () {
    return navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
  isFbMiniprogram: function () {
    return navigator.userAgent.match(/FBMP/i) ? true : false;
  },
  isFbWebview: function () {
    return navigator.userAgent.match(/fanbook/i) ? true : false;
  },
  isFb: function () {
    return navigator.userAgent.match(/(fanbook|FBMP|MacFB|WindowsFB)/i) ? true : false;
  },
  isMac: function () {
    return /macintosh|mac os x/i.test(navigator.userAgent);
  },
  isWindows: function () {
    return /windows|win32/i.test(navigator.userAgent);
  },
  isLinux: function () {
      return /linux/i.test(navigator.userAgent);
  },
  isFuchsia: function () {
      return /fuchsia/i.test(navigator.userAgent);
  },
  isWindowsWechat: function () {
    return /WindowsWechat/i.test(navigator.userAgent);
  },
};
export default browser;