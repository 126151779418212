
export default async function DLogRequest(dlogModelArray) {
  return fetch(`https://jdlog-h5.uu.cc/`, {
    method: "post",
    headers: {
      "content-type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(dlogModelArray.map(e=>e.toJson())),
  });
}
