import browser from "../browser";
class DLogModel {
    logType;
    clientTime;
    userId;
    appVersion;
    channel;
    platform;
    deviceId;
    appSessionId;

    actionEventId;
    actionEventSubId;
    actionEventSubParam;
    pageId;
    extJson;
    reportUa;

    constructor({ userId, actionEventId, actionEventSubId, actionEventSubParam, pageId, deviceId, platform, extJson = {}, reportUa = false }) {
        if(!platform) {
            if (browser.isIOS()) platform = '0';
            else if (browser.isAndroid()) platform = '1';
            else if (browser.isWindows()) platform = '3';
            else if (browser.isMac()) platform = '4';
            else if (browser.isLinux()) platform = '5';
            else if (browser.isFuchsia()) platform = '6';
            else platform = '2'
        }
        this.logType = 'dlog_app_action_event_fb'
        this.clientTime = getNowTime();
        this.userId = userId;
        this.platform = platform;
        this.appVersion = '';
        this.channel = 'web';
        this.deviceId = deviceId;
        this.appSessionId = '';
        this.actionEventId = actionEventId;
        this.actionEventSubId = actionEventSubId;
        this.actionEventSubParam = actionEventSubParam;
        this.pageId = pageId;
        this.extJson = extJson;
        this.reportUa = reportUa;
    }

    toJson() {
        if (this.reportUa) {
            this.extJson['ua'] = window.navigator.userAgent;
        }
        return {
            log_type: this.logType,
            client_time: this.clientTime,
            user_id: this.userId,
            app_version: this.appVersion,
            build_number: this.buildNumber,
            channel: this.channel,
            platform: this.platform,
            device_id: this.deviceId,
            app_session_id: this.appSessionId,
            action_event_id: this.actionEventId,
            action_event_sub_id: this.actionEventSubId,
            action_event_sub_param: this.actionEventSubParam,
            page_id: this.pageId,
            ext_json: this.extJson,
        };
    }
}

function getNowTime() {
    const now = new Date();
    let str = now.getFullYear();
    str += `-${addPadStart(now.getMonth() + 1)}`;
    str += `-${addPadStart(now.getDate())}`;
    str += ` ${addPadStart(now.getHours())}`;
    str += `:${addPadStart(now.getMinutes())}`;
    str += `:${addPadStart(now.getSeconds())}`;
    return str;
}
function addPadStart(num) {
    return `${num < 10 ? 0 : ''}${num}`
}

export default DLogModel;